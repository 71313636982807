import React from "react";
import { Col } from "react-bootstrap";

const Privacy = () => {
  return (
    <>
      <Col className="m-4 p-4">
        <h2 className="text-center">PRIVACY POLICY</h2>

        <p></p>
        <hr></hr>

        <p>
          Welcome to the website and mobile apps owned and operated by MW
          Studios (Singapore) LIMITED and its affiliates (&quot; MW Studios
          ,&quot; &quot;we&quot; or &quot;us&quot;),and MW Studios is committed
          to protecting your privacy. This Privacy Policy explains how
          information about you or associated with you (&quot;personal
          information&quot;) is collected, used and disclosed by MW Studios.
          This Privacy Policy applies to our websites, including
          https://www.yo-star.comand our mobile apps for iPhone, Android and
          Windows mobile devices (collectively, &quot;our Service&quot;). By
          accessing or using our Service, you signify that you have read,
          understood and agree to our collection, storage, use and disclosure of
          your personal information as described in this Privacy Policy.
        </p>

        <p></p>

        <p>Table of Contents</p>

        <p></p>

        <p>1. INFORMATION WE COLLECT AND USE</p>

        <p></p>

        <p>2. SHARING INFORMATION</p>

        <p></p>

        <p>3. YOUR CHOICES REGARDING YOUR INFORMATION</p>

        <p></p>

        <p>4. SECURITY AND STORAGE OF INFORMATION</p>

        <p></p>

        <p>5. PERSONS UNDER THE AGE OF 13</p>

        <p></p>

        <p>6. SENSITIVE PERSONAL DATA</p>

        <p></p>

        <p>7. COMPLAINTS</p>

        <p></p>

        <p>8. EU-U.S. PRIVACY SHIELD</p>

        <p></p>

        <p>9. LINKS TO THIRD PARTY WEBSITES</p>

        <p></p>

        <p>10. UPDATES TO THIS POLICY</p>

        <p></p>

        <p>11. USERS IN THE EEA</p>

        <p></p>

        <p>12. USERS IN CALIFORNIA</p>

        <p></p>

        <p>13. CONTACTING US</p>

        <p></p>

        <p>14. LAST REVISION DATE</p>

        <p></p>

        <p>1. INFORMATION WE COLLECT AND USE</p>

        <p></p>

        <p>
          Generally. We will only process your personal data in accordance with
          applicable data protection and privacy laws. We collect personal
          information from our users in order to provide you with a
          personalized, useful and efficient experience. If you created a
          profile/registered with us, you will have been asked to tick to agree
          to provide this information in order to access our Services. This
          consent provides us with the legal basis we require under applicable
          law to process your data. You maintain the right to withdraw such
          consent at any time. If you do not agree to our use of your personal
          data in line with this Policy, please do not use our Service.
        </p>

        <p></p>

        <p>The categories of information we collect can include:</p>

        <p></p>

        <p>
          Information you provide. You may use the Service without providing any
          personal information, but you must register and create a profile to
          participate in our official forums. We may collect and store personal
          information when you register for our Service or provide to us in some
          other manner, including your name, email address, user name and
          password. We may also collect any communications between you and MW
          Studios , as well as any information you provide if you take part in
          any interactive features of the Service. Information that is part of
          your profile will be viewable to the public and to other members of
          the Service.
        </p>

        <p>
          Data collected through the use of the Service. After you set up your
          account, you may choose to provide additional information which may be
          shared through public profiles, such as your photograph or avatar,
          biographical content, and other information (collectively,
          &quot;Profile Information&quot;). You control how much personally
          identifying information you wish to provide in your public profile. We
          also collect information about how you use the Service and about your
          actions on the Service, including your Service usage and activities,
          gaming levels, interactions with others on the Service and user
          content you post to the Service, and other content (&quot;User
          Content&quot;). Please remember that MW Studios may, but has no
          obligation to, monitor User Content posted by our members.
        </p>

        <p>
          Information we receive from third party sites. When you interact with
          our site through various social media, such as when you login through
          Facebook, Google + or another third party service, or share MW Studios
          content on Facebook, Twitter or other sites, we may receive
          information from the social network including your profile
          information, profile picture, gender, user name, user ID associated
          with your social media account, age range, language, country, friends
          list, and any other information you permit the social network to share
          with third parties. We may use your friends data to let you know what
          your friends are doing on our Service, and to let your friends know
          what you are doing on the Service. The data we receive is dependent
          upon your privacy settings with the social network, and we will not
          post information about you on third party social media sites without
          your consent. You should always review, and if necessary, adjust your
          privacy settings on third-party websites and services before linking
          or connecting them to our website or Service.
        </p>

        <p>
          Address Book Information. With your permission, MW Studios may access
          your contact list available on your mobile device or in your email
          accounts in order to locate your friends and contacts on the Service
          and to invite your friends and contacts to connect with our Service.
          When we invite your friends to join the Service, we will include your
          name and photo to let them know that you are the person extending the
          invitation. After sending these invitations, we may also send reminder
          emails to your invitees on your behalf. We will store these contacts
          for purposes of alerting you when your contacts join MW Studios at a
          later time so that you may connect with them on the Service, and to
          suggest friends and connections to other members of MW Studios .
        </p>

        <p>
          We use this information to operate, maintain, and provide to you the
          features and functionality of the Service, as well as to communicate
          directly with you, such as to send you email messages and push
          notifications, and permit you to communicate with others on the
          Service or on social media or invite others to join the Service. We
          may combine information internally across different Service offerings
          or games to enhance your experience with our Service. For example, we
          may identify a friend you may know from one service to suggest that
          you engage with that friend on another service, and we may let your
          friends on one service know about your activities on another service.
          For example, we may send an email to your friends on one of our Games
          to let them know that you're active on our Games, and we may include
          your profile picture in the email so that your friends recognize you.
          We may also use information about your activities on the Service to
          provide personalized content, including targeted content and
          advertising. We may also send you Service-related emails or messages
          (e.g., account verification, account status, change or updates to
          features of the Service, technical and security notices). For more
          information about your communication preferences, see &quot;Your
          Choices Regarding Your Information&quot; below.
        </p>

        <p></p>

        <p>Use of cookies and other technology to collect information.</p>

        <p></p>

        <p>
          We and our third party business partners automatically collect certain
          types of usage information when you visit our website or use our
          Service. When you visit the Service, we may send one or more cookies -
          a small text file containing a string of alphanumeric characters - to
          your computer that uniquely identifies your browser and lets us help
          you log in faster and enhance your navigation through the site. A
          cookie may also convey information to us about how you use the Service
          (e.g., the pages you view, the links you click, how frequently you
          access the Service, and other actions you take on the Service), and
          allow us to track your usage of the Service over time. We may collect
          log file information from your browser or mobile device each time you
          access the Service. Log file information may include anonymous
          information such as your web request, Internet Protocol
          (&quot;IP&quot;) address, browser type, information about your mobile
          device, referring / exit pages and URLs, number of clicks and how you
          interact with links on the Service, domain names, landing pages, pages
          viewed, and other such information. We may employ clear gifs (also
          known as web beacons) which are used to anonymously track the online
          usage patterns of our Users. In addition, we may also use clear gifs
          in HTML-based emails sent to our users to track which emails are
          opened and which links are clicked by recipients. The information
          allows for more accurate reporting and improvement of the Service. We
          may also collect analytics data, or use third-party analytics tools,
          to help us measure traffic and usage trends for the Service and to
          understand more about the demographics of our users. These tools
          collect information sent by your browser or mobile device, including
          the pages you visit, your use of third party applications, and other
          information that assists us in analyzing and improving the Service.
          Although we do our best to honor the privacy preferences of our
          visitors, we are not able to respond to Do Not Track signals from your
          browser at this time.
        </p>

        <p></p>

        <p>
          When you access our Service by or through a mobile device, we may
          receive or collect and store a unique identification numbers
          associated with your device or our mobile application (including, for
          example, a unique device ID, Unique ID for Advertisers
          (&quot;IDFA&quot;), Google Ad ID, or Windows Advertising ID), MAC
          address, mobile carrier, device type, model and manufacturer, mobile
          device operating system brand and model, phone number, and, depending
          on your mobile device settings. In addition, we create a unique user
          ID to track your use of the Service. This unique user ID helps track
          the games you are playing.
        </p>

        <p></p>

        <p>
          We use or may use the data collected through cookies, log file, device
          identifiers, location data and clear gifs information to: (a) remember
          information so that you will not have to re-enter it during your visit
          or the next time you visit the site; (b) provide custom, personalized
          content and information, including targeted content and advertising;
          (c) provide and monitor the effectiveness of our Service; (d) monitor
          aggregate metrics such as total number of visitors, traffic, usage,
          and demographic patterns on our website and our Service; (e) diagnose
          or fix technology problems; and (f) otherwise to plan for and enhance
          our service.
        </p>

        <p></p>

        <p>2. SHARING INFORMATION</p>

        <p></p>

        <p>
          We may share your personal information in the instances described
          below. For further information on your choices regarding your
          information, see the &quot;Your Choices Regarding Your
          Information&quot; section below.
        </p>

        <p></p>

        <p>We may also share your personal information with:</p>

        <p></p>

        <p>
          a.Third parties at your request. For example, you may have the option
          to share your activities on MW Studios with your friends through
          email, text or on various social media sites;
        </p>

        <p>
          b.Other brands owned or controlled by MW Studios and other companies
          owned by or under common ownership as MW Studios , which also includes
          our subsidiaries (i.e., any organization we own or control) or our
          ultimate holding company (i.e., any organization that owns or controls
          us) and any subsidiaries it owns. These companies will use your
          personal information in the same way as we can under this Policy;
        </p>

        <p>
          c.Other parties whose products or services we believe may be of
          interest to you;
        </p>

        <p>
          d.Third party vendors, consultants and other service providers that
          perform services on our behalf, in order to carry out their work for
          us, which may include identifying and serving targeted advertisements,
          content or service fulfillment, or providing analytics services;
        </p>

        <p>
          e.Other parties in connection with any company transaction, such as a
          merger, sale of all or a portion of company assets or shares,
          reorganization, financing, change of control or acquisition of all or
          a portion of our business by another company or third party or in the
          event of bankruptcy or related or similar proceedings; and
        </p>

        <p>
          f.Third parties as required to ( i ) satisfy any applicable law,
          regulation, subpoena/court order, legal process or other government
          request, (ii) enforce our Terms of Use Agreement, including the
          investigation of potential violations thereof, (iii) investigate and
          defend ourselves against any third party claims or allegations, (iv)
          protect against harm to the rights, property or safety of MW Studios ,
          its users or the public as required or permitted by law and (v)
          detect, prevent or otherwise address criminal (including fraud or
          stalking), security or technical issues.
        </p>

        <p>
          We may also share information with others in an aggregated and
          anonymous form that does not reasonably identify you directly as an
          individual.
        </p>

        <p></p>

        <p>3. YOUR CHOICES REGARDING YOUR INFORMATION</p>

        <p></p>

        <p>
          Profile and Data Sharing Settings. You may change some of your data
          sharing preferences on your Settings page.
        </p>

        <p></p>

        <p>
          Push Notifications.We may occasionally send you push notifications
          through our mobile applications to send you game updates, high scores
          and other service related notifications that may be of importance to
          you. You may at any time opt-out from receiving these types of
          communications by turning them off at the device level through your
          settings.
        </p>

        <p></p>

        <p>
          Marketing Communications. If you do not wish to receive promotional
          emails, you can click the &quot;unsubscribe&quot; button on
          promotional email communications. Note that you are not permitted to
          unsubscribe or opt-out of non-promotional messages regarding your
          account, such as account verification, change or updates to features
          of the Service, or technical and security notices.
        </p>

        <p></p>

        <p>
          Online Advertising.We may permit third party online advertising
          networks, social media companies, and other parties to collect
          information about your use of our website and apps over time so that
          they may play or display ads that may be relevant to your interests on
          our Service as well as on other websites or apps. Typically, the
          information we share is provided through cookies or similar tracking
          technologies. The only way to completely &quot;opt out&quot; of the
          collection of any information through cookies or other tracking
          technology is to actively manage the settings on your browser or
          mobile device. Please refer to your browser's or mobile device's
          technical information for instructions on how to delete and disable
          cookies, and other tracking/recording tools. (To learn more about
          cookies, clear gifs/web beacons and related technologies, you may wish
          to visit http://www.allaboutcookies.org and/or the Network Advertising
          Initiative's online resources, at https://www.networkadvertising.org
          ). If you access our service on your mobile device, you may not be
          able to control tracking technologies through settings.
        </p>

        <p></p>

        <p>
          Data Retention: We will retain your information for as long as your
          account is active or as needed to provide you services. Please contact
          us at play@mwstudios.io if you wish to delete your account permanently
          from our systems. Please be aware that we will not be able to delete
          any content you have shared with others or with social media sites.
        </p>

        <p></p>

        <p>
          Deleting or Anonymizing Your Content: If you are under 18 years of
          age, you may request that the User Content that you posted to our
          Service be deleted or anonymized such that your personal information
          will not be identifiable publicly on our Service by contacting us at
          play@mwstudios.io. While we will use all commercially reasonable
          efforts to delete or anonymize your User Content upon request, please
          be aware that due to the social nature of our Service, you may not be
          able to completely remove all of your personally identifiable User
          Content if, for example, that content has been stored, republished, or
          reposted by another user or a third party. We may also maintain your
          information in identifiable form for our internal use, even if your
          personal data is no longer visible to the public on our Service.
        </p>

        <p></p>

        <p>4. SECURITY AND STORAGE OF INFORMATION</p>

        <p></p>

        <p>
          MW Studios cares about the security of your information and uses
          commercially reasonable physical, administrative, and technological
          safeguards to preserve the integrity and security of all information
          we collect and that we share with our service providers. However, no
          security system is impenetrable and we cannot guarantee the security
          of our systems 100%. In the event that any information under our
          control is compromised as a result of a breach of security, we will
          take reasonable steps to investigate the situation and where
          appropriate, notify those individuals whose information may have been
          compromised and take other steps, in accordance with any applicable
          laws and regulations.
        </p>

        <p></p>

        <p>
          Your information collected through our Service may be stored and
          processed in Singapore or any other country or region in which MW
          Studios or its subsidiaries, affiliates or service providers maintain
          facilities. If you are located in the European Union or other regions
          with laws governing data collection and use that may differ from
          Singapore law, please note that we may transfer information, including
          personal information, to a country and jurisdiction that does not have
          the same data protection laws as your jurisdiction, and you consent to
          the transfer of information to Singapore or any other country or
          region in which Company or its parent, subsidiaries, affiliates or
          service providers maintain facilities and the use and disclosure of
          information about you as described in this Privacy Policy.
        </p>

        <p></p>

        <p>5. PERSONS UNDER THE AGE OF 13</p>

        <p></p>

        <p>
          The Service and its content are not directed at children under the age
          of 13. In the event that we learn that we have collected personal
          information from a child under age 13 without parental consent, we
          will delete that information as quickly as possible. If you believe
          that we might have any information collected from a child under 13,
          please contact us at play@mwstudios.io.
        </p>

        <p></p>

        <p>6. SENSITIVE PERSONAL DATA</p>

        <p></p>

        <p>
          Subject to the following paragraph, we ask that you not send us, and
          you not disclose, any sensitive personal data (e.g., social security
          numbers, information related to racial or ethnic origin, political
          opinions, religion or other beliefs, health, biometrics or genetic
          characteristics, criminal background or trade union membership) on or
          through our Forums, Social Media, Service or otherwise to us.
        </p>

        <p></p>

        <p>
          If you send or disclose any sensitive personal data to us when you
          submit user generated content to our Forums, Social Media or Service,
          you consent to our processing and use of such sensitive personal data
          in accordance with this Policy. If you do not consent to our
          processing and use of such sensitive personal data, you must not
          submit such user generated content to our Forums, Social Media,
          Service or otherwise to us.
        </p>

        <p></p>

        <p>7. COMPLAINTS</p>

        <p></p>

        <p>
          We are committed to resolve any complaints about our collection or use
          of your personal data. If you would like to make a complaint regarding
          this Policy or our practices in relation to your personal data, please
          contact us at: play@mwstudios.io. We will reply to your complaint as
          soon as we can and in any event, within 45 days. We hope to resolve
          any complaint brought to our attention, however if you feel that your
          complaint has not been adequately resolved, you reserve the right to
          contact your local data protection supervisory authority, which for
          the UK, is the Information Commissioner&rsquo;s Office.
        </p>

        <p></p>

        <p>
          We are committed to processing your data in accordance with the
          required standards. This includes protecting your privacy and ensuring
          the security of your data in compliance with, in particular and where
          applicable, the requirements of the Personal Data (Privacy) Ordinance
          (Chapter 486 of the Laws of Singapore), Regulation (EU) 2016/679 of
          the European Parliament and of the Council of 27 April 2016 on the
          protection of natural persons with regard to the processing of
          personal data and on the free movement of such data where applicable.
        </p>

        <p></p>

        <p>8. EU-U.S. PRIVACY SHIELD</p>

        <p></p>

        <p>
          MW Studios complies with the EU-U.S. Privacy Shield Framework as set
          forth by the U.S. Department of Commerce regarding the collection,
          use, and retention of personal data transferred from the EU to the
          United States. We have certified to the Department of Commerce that we
          adhere to the Privacy Shield Principles. If there is any conflict
          between the terms in this Policy and the Privacy Shield Principles,
          the Privacy Shield Principles shall take precedence. To learn more
          about the Privacy Shield program, the Privacy Shield Principles and to
          view our certification, please visit www.privacyshield.gov .
        </p>

        <p></p>

        <p>
          As described in the Privacy, MW Studios is accountable for personal
          data that it receives and subsequently transfers to third parties. If
          third parties that process personal data on our behalf do so in a
          manner that does not comply with the Privacy Shield Principles, we are
          accountable, unless we prove that we are not responsible for the event
          giving rise to the damage.
        </p>

        <p></p>

        <p>
          In compliance with the Privacy Shield Principles, MW Studios commits
          to resolve complaints about our collection or use of your personal
          data. EU individuals with inquiries or complaints regarding this
          Policy should first contact us at: play@mwstudios.io.
        </p>

        <p></p>

        <p>
          MW Studios has further committed to refer unresolved Privacy Shield
          complaints to Jams, an alternative dispute resolution provider located
          in the United States or. If you do not receive timely acknowledgment
          of your complaint from us, or if we have not resolved your complaint,
          please visit https://jamsadr.com for more information or to file a
          complaint. The services of Jams are provided at no cost to you.
        </p>

        <p></p>

        <p>9. LINKS TO THIRD PARTY WEBSITES</p>

        <p></p>

        <p>
          The Service may contain links to and from third party websites of our
          business partners, advertisers, social media sites, and our users may
          post links to third party websites. If you follow a link to any of
          these websites, please note that these websites have their own privacy
          policies and that we do not accept any responsibility or liability for
          their policies. Please check the individual policies before you submit
          any information to those websites.
        </p>

        <p></p>

        <p>10. UPDATES TO THIS POLICY</p>

        <p></p>

        <p>
          We reserve the right to modify this Policy from time to time. If we
          make any changes to this Policy, we will change the &quot;Last
          Revision&quot; date below and will post the updated Policy on this
          page. If you object to any changes, you may close your account.
          Continuing to use our Service after we publish changes to this Privacy
          Policy means that you are consenting to the changes.
        </p>

        <p></p>

        <p>11. USERS IN THE EEA</p>

        <p></p>

        <p>
          As set out in more detail below, we only process or transfer your
          personal information where we can rely on legal grounds and
          appropriate safeguards to do so. You also have additional rights
          regarding your personal information if you are located in the European
          Economic Area (&ldquo;EEA&rdquo;).
        </p>

        <p></p>

        <p>(1) Legal Basis for Processing Your Personal Information</p>

        <p>
          We only process your personal information where we can rely on legal
          grounds to do so. We process your personal information for the
          purposes set out below.
        </p>

        <p>a. Performance of the Services</p>

        <p>
          We process your personal information for the performance of our
          services, to provide or support of our products, or for any other
          feature you request or enable. This includes, for example, using your
          personal information to:
        </p>

        <p>&bull; administer your account;</p>

        <p>&bull; provide contests or promotions in which you have enrolled;</p>

        <p>&bull; support purchases you make;</p>

        <p>&bull; support game functionality;</p>

        <p>&bull; provide global customer service; or</p>

        <p>
          &bull; provide a fair gaming experience by using anti-fraud
          technologies such as bans or blocks of accounts.
        </p>

        <p>b. Consent</p>

        <p>
          We process your personal information based on your consent. This
          includes, for example, providing:
        </p>

        <p>
          &bull; newsletters, direct e-mails and surveys about our Properties;
          and
        </p>

        <p>&bull; certain other marketing features</p>

        <p>c. Legitimate Interest</p>

        <p>
          We process your personal information where we have a legitimate
          interest to do so. This includes, for example, processing:
        </p>

        <p>
          &bull; to provide you with requested customer service or technical
          support;
        </p>

        <p>&bull; to debug and improve our current and future Properties;</p>

        <p>
          &bull; in order to give you exclusive content, personalize your online
          experience with us and contact you in accordance with applicable
          marketing preferences; and
        </p>

        <p>
          &bull; for the establishment, exercise or defense of legal claims or
          whenever courts are acting in their judicial capacity.
        </p>

        <p>d. Legal Obligation</p>

        <p>
          We process your personal information for compliance with a legal
          obligation to which we are subject.
        </p>

        <p></p>

        <p></p>

        <p>(2) Your Rights to Your Personal Information</p>

        <p>
          You have the following rights in addition to your rights to view,
          correct, update, or request the deletion of your personal information
        </p>

        <p>
          You may object to the processing of your personal information based on
          a legitimate interest on grounds relating to your particular
          situation. You may, in particular, control the extent to which we
          market to you and you have the right to request that we stop sending
          you marketing messages at any time.
        </p>

        <p>
          In certain circumstances, you can request that we transfer personal
          information that you have provided to us.
        </p>

        <p>
          Where we rely on your consent in order to process your personal
          information, you have the right to withdraw such consent to further
          use of your personal information at any time.
        </p>

        <p>
          For each of the rights described above, you can contact us at
          play@mwstudios.io.
        </p>

        <p>
          We hope that we can satisfy queries you may have about the way we
          process your personal information. However, if you have unresolved
          concerns you also have the right to complain to data protection
          authorities. You can bring the complaint in your member state of
          residence, place of work or where an alleged infringement of data
          protection law occurred.
        </p>

        <p></p>

        <p></p>

        <p>(3) International Transfers</p>

        <p>
          Your personal information will be transferred to and processed in the
          United States. We use European Commission approved contract clauses to
          protect your personal information. If you have questions, please
          contact us at play@mwstudios.io.
        </p>

        <p></p>

        <p> 12. USERS IN CALIFORNIA</p>

        <p>
          The California Consumer Privacy Act (&ldquo;CCPA&rdquo;) grants
          California residents certain privacy rights regarding personal data we
          collect. We are committed to respecting these rights and complying
          with the CCPA. The following explains these rights and MW Studios's
          practices with respect to them.
        </p>

        <p>
          Right to Know. Under the CCPA you have the right to request that we
          disclose to you what personal data we collect, use, disclose and
          share.
        </p>

        <p>
          Exercising Your Rights. California residents may exercise you rights
          by submitting your request to us by the email: play@mwstudios.io .For
          security purposes, we will verify your identity &ndash; including
          requesting information from you &ndash; when you request to exercise
          your rights. If you have an online account you use to interact with MW
          Studios, logging into your account will serve to verify your identity
          and request. We may request additional information if we believe your
          account has been compromised. If you do not have an account with us,
          or if we have reasons to suspect that the security of your account is
          compromised, then we will request additional information from you to
          match with our existing records to verify your identity, depending on
          the nature of the request and the sensitivity of the information
          sought.
        </p>

        <p>
          Once we have verified your identity (and your agent, as applicable),
          we will respond to your request as appropriate. If we are unable to
          complete your requests, we will provide you information about the
          reasons that we could not comply with your request.
        </p>

        <p>
          Right to Request Deletion. You also have the right to request deletion
          of your personal data that is in our possession, subject to certain
          exceptions. Please note that your request by contacting us at;
          play@mwstudios.io to delete data may impact your use of our Service in
          some cases, and we may decline to delete information for reasons set
          forth in this Privacy Policy or as permitted by the CCPA.
        </p>

        <p>
          Other Rights. The CCPA also gives California residents a right to
          opt-out from the sale of their Personal Data. We do not sell your
          personal data and have not done so in the past 12 months. Furthermore,
          you have a right to not be discriminated against for exercising your
          rights under the CCPA.
        </p>

        <p>
          You may designate, in writing or through a power of attorney, an
          authorized agent to make requests on your behalf to exercise your
          rights under the CCPA. Before accepting such a request from an agent,
          we will require the agent to provide proof you have authorized it to
          act on your behalf, and we may need you to verify your identity
          directly with us.
        </p>

        <p></p>

        <p></p>

        <p>13. CONTACTING US</p>

        <p></p>

        <p>
          If you have questions about this Privacy Policy, please contact us at
          play@mwstudios.io or by writing to us at:
        </p>

        <p></p>

        <p>
          MW Studios (Singapore) LIMITED, Address: 12 Woodlands Sq. #14 - 76
          Woodsquare, Tower 1, Singapore 737715
        </p>

        <p></p>

        <p></p>

        <p>14. LAST REVISION DATE</p>

        <p></p>

        <p>
          This Policy was last revised on Aug,11th, and effective as of Aug,11th
          , 2020.
        </p>

        <p></p>

        <p></p>
      </Col>
    </>
  );
};

export default Privacy;
