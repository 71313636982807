import React, { useEffect } from "react";
import { Container, Row, Col, Image, Nav } from "react-bootstrap";

import "./FooterBar.css";
import logo from "../assets/logo.png";
import facebook from "../assets/icon_facebook.png";
import tiktok from "../assets/icon_tiktok.png";
import discord from "../assets/icon_discord.png";
import instagram from "../assets/icon_instagram.png";
import AppDownloadBar from "./AppDownloadBar.jsx";
import $ from "jquery";

const FooterBar = () => {
  const privacyView = () => {
    $("#terms").addClass("d-none");
    $("#main-content").addClass("d-none");
    $("#privacy").removeClass("d-none");
    window.scrollTo(0, 0);
  };

  const termsView = () => {
    $("#main-content").addClass("d-none");
    $("#privacy").addClass("d-none");
    $("#terms").removeClass("d-none");
    window.scrollTo(0, 0);
  };

  const width = window.innerWidth;
  if (width > 768) {
    return (
      <>
        <Container
          className="d-flex main-footer align-items-center justify-content-center footer"
          fluid
        >
          <Row className="align-items-center">
            <Col className="logo-size h-50">
              <Image src={logo} />
            </Col>
            <Col className="d-flex flex-column text-center p-4 m-4">
              <Row className="mx-4 my-2">
                <AppDownloadBar />
              </Row>
              <Row className="d-flex justify-content-center m-2 mt-4">
                <Col className="d-flex align-items-center justify-content-center">
                  <Nav.Link href="https://google.com">
                    <Image src={discord} fluid />
                  </Nav.Link>
                </Col>

                <Col className="d-flex align-items-center justify-content-center">
                  <Nav.Link href="https://google.com">
                    <Image src={facebook} fluid />
                  </Nav.Link>
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                  <Nav.Link href="https://google.com">
                    <Image src={instagram} fluid />
                  </Nav.Link>
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                  <Nav.Link href="https://google.com">
                    <Image src={tiktok} fluid />
                  </Nav.Link>
                </Col>
              </Row>
              <Row className="m-2 mt-4">
                <Nav className="d-flex justify-content-center">
                  <Nav.Item as="li">
                    <Nav.Link
                      href="/#terms"
                      onClick={termsView}
                      className="text-white"
                    >
                      Terms and Conditions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      href="/#privacy"
                      onClick={privacyView}
                      className="text-white"
                    >
                      Privacy Policy
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container className="p-4 footer" fluid>
          <Col>
            <Row className="">
              <AppDownloadBar className="" />
            </Row>
            <Row className="mb-4">
              <Nav className="d-flex justify-content-center align-items-center footer-links">
                <Nav.Item as="li">
                  <Nav.Link
                    href="/#terms"
                    onClick={termsView}
                    className="text-white"
                  >
                    Terms and Conditions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    href="/#privacy"
                    onClick={privacyView}
                    className="text-white"
                  >
                    Privacy Policy
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Nav.Link href="https://google.com">
                  <Image src={discord} fluid />
                </Nav.Link>
              </Col>

              <Col className="d-flex align-items-center justify-content-center">
                <Nav.Link href="https://google.com">
                  <Image src={facebook} fluid />
                </Nav.Link>
              </Col>
              <Col className="d-flex align-items-center justify-content-center">
                <Nav.Link href="https://google.com">
                  <Image src={instagram} fluid />
                </Nav.Link>
              </Col>
              <Col className="d-flex align-items-center justify-content-center">
                <Nav.Link href="https://google.com">
                  <Image src={tiktok} fluid />
                </Nav.Link>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col className="mt-3 w-50 logo-size-sm">
                <Image className="" src={logo} />
              </Col>
            </Row>
          </Col>
        </Container>
      </>
    );
  }
};

export default FooterBar;
