import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Introduction.css";

const Introduction = () => {
  let width = window.innerWidth;

  if (width > 768) {
    return (
      <>
        <Col className="intro-image-big" />
      </>
    );
  } else {
    return (
      <>
        <Col className="intro-image " />
      </>
    );
  }
};

export default Introduction;
