import React from "react";
import { Col } from "react-bootstrap";

import "./MainBanner.css";

const MainBanner = () => {
  let width = window.innerWidth;
  if (width > 768) {
    return (
      <>
        <Col id="introduction" className="main-bg"></Col>
      </>
    );
  } else {
    return (
      <>
        <Col id="introduction" className="main-bg-small"></Col>
      </>
    );
  }
};

export default MainBanner;
