import React from "react";
import { Col } from "react-bootstrap";

const Terms = () => {
  return (
    <>
      <Col className="m-4 p-4">
        <h2 className="text-center">TERMS OF SERVICE</h2>

        <p></p>
        <hr></hr>

        <p>
          Welcome to MW STUDIOS (SINGAPORE) PRIVATE LIMITED, the website and MW
          Studios (Singapore) LIMITED (&quot;MW Studios&quot;, &quot;we&quot; or
          &quot;us&quot;). This page explains the terms by which you may use our
          online and/or mobile services, website, games, and software provided
          on or in connection with the service (collectively the
          &quot;Service&quot;). By installing, accessing, or using the Service,
          or by checking a box or clicking a button signifying your assent to
          these terms, you signify that you have read, understood, and agree to
          be bound by this Terms of Use Agreement (&quot;Agreement&quot;) and to
          the collection and use of your information as set forth in the MW
          Studios Privacy Policy https://www.arknights.global/privacy_policy,
          whether or not you are a registered user of our Service. MW Studios
          reserves the right to modify these terms and will provide notice of
          these changes as described below. This Agreement applies to all
          visitors, users, and others who access the Service
          (&quot;Users&quot;).
        </p>

        <p></p>

        <p>
          Please read this Agreement carefully to ensure that you understand
          each provision. This Agreement contains a mandatory individual
          arbitration and class action/jury trial waiver provision that requires
          the use of arbitration on an individual basis to resolve disputes,
          rather than jury trials or class actions.
        </p>

        <p></p>

        <p>1. Use of Our Service</p>

        <p></p>

        <p>
          MW Studios develops and publishes free-to-play mobile games that are
          available to play on the iOS and Android platforms.
        </p>

        <p></p>

        <p>A. Eligibility</p>

        <p></p>

        <p>
          This is a contract between you and MW Studios. You must read and agree
          to these terms before using the Service. If you do not agree, you may
          not use the Service. You may use the Service only if you can form a
          binding contract with MW Studios, and only in compliance with this
          Agreement and all applicable local, state, national, and international
          laws, rules and regulations. Any use or access to the Service by
          anyone under 13 is strictly prohibited and in violation of this
          Agreement. If you are between the ages of 13 and 17, you represent
          that your legal guardian has reviewed and agreed to this Agreement on
          your behalf. The Service is not available to any Users previously
          removed from the Service by MW Studios.
        </p>

        <p></p>

        <p>B. Service</p>

        <p></p>

        <p>
          Subject to the terms and conditions of this Agreement, you are hereby
          granted a non-exclusive, limited, non-transferable, freely revocable
          license to use the Service for your personal, noncommercial use only
          and as permitted by the features of the Service. MW Studios reserves
          all rights not expressly granted herein in the Service and the MW
          Studios Content (as defined below). MW Studios may terminate this
          license at any time for any reason or no reason.
        </p>

        <p></p>

        <p>C. Accounts on the Service</p>

        <p></p>

        <p>
          Your Service account gives you access to the services and
          functionality that we may establish and maintain from time to time and
          in our sole discretion. By connecting to the Service with a
          third-party service, such as Facebook, Amazon, Google+, or any other
          third-party service, you give us permission to access and use your
          information from that service as permitted by that service, and to
          store your log-in credentials for that service.
        </p>

        <p></p>

        <p>
          You may only use and operate one (1) User account at a time unless you
          have otherwise been given express prior written permission to do so
          from MW Studios. You may never use another User's account without
          permission. Further, you may not sell, gift, trade, or transfer your
          User account to any other User for any reason whatsoever.
        </p>

        <p></p>

        <p>
          When creating your account, you must provide accurate and complete
          information, and you must keep this information up to date. You are
          solely responsible for the activity that occurs on your account, and
          you must keep your account password secure. We encourage you to use
          &quot;strong&quot; passwords (passwords that use a combination of
          upper and lower case letters, numbers and symbols) with your account.
          You must notify MW Studios immediately of any breach of security or
          unauthorized use of your account. MW Studios will not be liable for
          any losses caused by any unauthorized use of your account.
        </p>

        <p></p>

        <p>
          When creating your account regarding the Service, you are prohibited
          from using politically sensitive, pornographic or any other
          inappropriate word, symbol or image in your account. It is in MW
          Studios&rsquo;s sole discretion to censor and decide whether the
          content of your account is prohibited and MW Studios is entitled to
          promptly cancel, suspend or nullifyyour account once it is deemed
          prohibited.
        </p>

        <p></p>

        <p>
          You may control your User profile and how you interact with the
          Service by changing the settings within your settings' menu. By
          providing MW Studios your email address you consent to our using the
          email address to send you Service-related notices, including any
          notices required by law, in lieu of communication by postal mail. We
          may also use your email address to send you other messages, such as
          changes to features of the Service and special offers. If you do not
          want to receive such email messages, you may opt out or change your
          preferences in your settings' menu. Opting out may prevent you from
          receiving email messages regarding updates, improvements, or offers.
        </p>

        <p></p>

        <p>D.Service Rules</p>

        <p></p>

        <p>
          You agree not to engage in any of the following prohibited activities:
          (i) copying, distributing, or disclosing any part of the Service in
          any medium, including without limitation by any automated or
          non-automated &quot;scraping&quot;; (ii) using any automated system,
          including without limitation &quot;robots&quot;, &quot;spiders&quot;,
          &quot;offline readers&quot;, etc., to access the Service in a manner
          that sends more request messages to the MW Studios servers than a
          human can reasonably produce in the same period of time by using a
          conventional on-line web browser (except that MW Studios grants the
          operators of public search engines revocable permission to use spiders
          to copy publically available materials from the Service for the sole
          purpose of and solely to the extent necessary for creating publicly
          available searchable indices of the materials, but not caches or
          archives of such materials); (iii) transmitting spam, chain letters,
          or other unsolicited email; (iv) attempting to interfere with,
          compromise the system integrity or security or decipher any
          transmissions to or from the servers running the Service; (v) taking
          any action that imposes, or may impose at our sole discretion an
          unreasonable or disproportionately large load on our infrastructure;
          (vi) uploading invalid data, viruses, worms, or other software agents
          through the Service; (vii) collecting or harvesting any personally
          identifiable information, including account names, from the Service;
          (viii) using the Service for any commercial solicitation purposes;
          (ix) impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or
          attempting to hide your identity; (x) interfering with the proper
          working of the Service; (xi) accessing any content on the Service
          through any technology or means other than those provided or
          authorized by the Service; (xii) bypassing the measures we may use to
          prevent or restrict access to the Service, including without
          limitation features that prevent or restrict use or copying of any
          content or enforce limitations on use of the Service or the content
          therein; (xiii) using cheats, exploits, automation software, bots,
          hacks, mods, or any unauthorized third-party software that modifies or
          interferes with the Service or any User's game experience; or (xiv)
          using or operating more than one (1) User account at a time without MW
          Studios's prior written consent.
        </p>

        <p></p>

        <p>
          Accessing any audiovisual content that may be available on the Service
          for any purpose or in any manner other than Streaming (as defined
          below) is expressly prohibited unless explicitly permitted by the
          functionality of the Service. &quot;Streaming&quot; means a
          contemporaneous digital transmission of an audiovisual work via the
          Internet from the Service to a User's device in such a manner that the
          data is intended for real-time viewing and not intended to be copied,
          stored, permanently downloaded, or redistributed by the User.
        </p>

        <p></p>

        <p>
          We may, without prior notice, change the Service; stop providing the
          Service or features of the Service, to you or to Users generally; or
          create usage limits for the Service. We may permanently or temporarily
          terminate or suspend your access to the Service without notice and
          liability for any reason, including if in our sole determination you
          violate any provision of this Agreement, or for no reason. Upon
          termination for any reason or no reason, you continue to be bound by
          this Agreement.
        </p>

        <p></p>

        <p>
          You are solely responsible for your interactions with other Users. We
          reserve the right, but have no obligation, to monitor disputes between
          you and other Users. MW Studios shall have no liability for your
          interactions with other Users, or for any User's action or inaction.
        </p>

        <p></p>

        <p>2. User Content</p>

        <p></p>

        <p>
          Some areas of the Service allow Users to post or provide content such
          as profile information, videos, images, music, comments, questions,
          and other content or information (any such materials a User submits,
          posts, displays, or otherwise makes available on the Service is
          referred to as&quot;User Content&quot;). We claim no ownership rights
          over User Content created by you. The User Content you create remains
          yours; however, by providing or sharing User Content through the
          Service, you agree to allow others to view, edit, and/or share your
          User Content in accordance with your settings and this Agreement. MW
          Studios has the right (but not the obligation) in its sole discretion
          to remove any User Content that is shared via the Service.
        </p>

        <p></p>

        <p>
          You agree not to post User Content that: (i) may create a risk of
          harm, loss, physical or mental injury, emotional distress, death,
          disability, disfigurement, or physical or mental illness to you, to
          any other person, or to any animal; (ii) may create a risk of any
          other loss or damage to any person or property; (iii) seeks to harm or
          exploit children by exposing them to inappropriate content, asking for
          personally identifiable details or otherwise; (iv) may constitute or
          contribute to a crime or tort; (v) contains any information or content
          that we deem to be unlawful, harmful, abusive, racially or ethnically
          offensive, defamatory, infringing, invasive of personal privacy or
          publicity rights, harassing, humiliating to other people (publicly or
          otherwise), libelous, threatening, profane, obscene, or otherwise
          objectionable; (vi) contains any information or content that is
          illegal (including, without limitation, the disclosure of insider
          information under securities law or of another party's trade secrets);
          (vii) contains any information or content that you do not have a right
          to make available under any law or under contractual or fiduciary
          relationships; or (viii) contains any information or content that you
          know is not correct and current or (ix) violates any school or other
          applicable policy, including those related to cheating or ethics. You
          agree that any User Content that you post does not and will not
          violate third-party rights of any kind, including without limitation
          any Intellectual Property Rights (as defined below) or rights of
          privacy. To the extent that your User Content contains music, you
          hereby represent that you are the owner of all the copyright rights,
          including without limitation the performance, mechanical, and sound
          recordings rights, with respect to each and every musical composition
          (including lyrics) and sound recording contained in such User Content
          and have the power to grant the license granted below. MW Studios
          reserves the right, but is not obligated, to reject and/or remove any
          User Content that MW Studios believes, in its sole discretion,
          violates any of these provisions. You understand that publishing your
          User Content on the Service is not a substitute for registering it
          with the copyrights authorities, guilds or any other governmental or
          non-governmental organization.
        </p>

        <p></p>

        <p>
          For the purposes of this Agreement, &quot;Intellectual Property
          Rights&quot; means all patent rights, copyright rights, mask work
          rights, moral rights, rights of publicity, trademark, trade dress and
          service mark rights, goodwill, trade secret rights and other
          intellectual property rights as may now exist or hereafter come into
          existence, and all applications therefore and registrations, renewals
          and extensions thereof, under the laws of any state, country,
          territory or other jurisdiction.
        </p>

        <p></p>

        <p>
          In connection with your User Content, you affirm, represent and
          warrant the following:
        </p>

        <p></p>

        <p>
          A. You have the written consent of each and every identifiable natural
          person in the User Content, if any, to use such person&rsquo;s name or
          likeness in the manner contemplated by the Service and this Agreement,
          and each such person has released you from any liability that may
          arise in relation to such use.
        </p>

        <p></p>

        <p>
          B. You have obtained and are solely responsible for obtaining all
          consents as may be required by law to post any User Content relating
          to third parties.
        </p>

        <p></p>

        <p>
          C. Your User Content and MW Studios's use thereof as contemplated by
          this Agreement and the Service will not violate any law or infringe
          any rights of any third party, including but not limited to any
          Intellectual Property Rights and privacy rights.
        </p>

        <p></p>

        <p>
          D. MW Studios may exercise the rights to your User Content granted
          under this Agreement without liability for payment of any guild fees,
          residuals, payments, fees, or royalties payable under any collective
          bargaining agreement or otherwise.
        </p>

        <p></p>

        <p>
          MW Studios takes no responsibility and assumes no liability for any
          User Content that you or any other User or third party posts, sends,
          or otherwise makes available over the Service. You shall be solely
          responsible for your User Content and the consequences of posting,
          publishing it, sharing it, or otherwise making it available on the
          Service, and you agree that we are only acting as a passive conduit
          for your online distribution and publication of your User Content. You
          understand and agree that you may be exposed to User Content that is
          inaccurate, objectionable, inappropriate for children, or otherwise
          unsuited to your purpose, and you agree that MW Studios shall not be
          liable for any damages you allege to incur as a result of or relating
          to any User Content.
        </p>

        <p></p>

        <p>3. User Content License Grant</p>

        <p></p>

        <p>
          By posting or otherwise making available any User Content on or
          through the Service, you expressly grant, and you represent and
          warrant that you have all rights necessary to grant, to MW Studios a
          royalty-free, sublicensable, transferable, perpetual, irrevocable,
          non-exclusive, worldwide license to use, reproduce, modify, publish,
          list information regarding, edit, translate, distribute, syndicate,
          publicly perform, publicly display, and make derivative works of all
          such User Content and your name, voice, and/or likeness as contained
          in your User Content, in whole or in part, and in any form, media or
          technology, whether now known or hereafter developed, for use in
          connection with the Service and MW Studios's (and its successors' and
          affiliates') business, including without limitation for promoting and
          redistributing part or all of the Service (and derivative works
          thereof) in any media formats and through any media channels. You also
          hereby grant each User of the Service a non-exclusive license to
          access your User Content through the Service, and to use, reproduce,
          distribute, display and perform such User Content as permitted through
          the functionality of the Service and under this Agreement.
        </p>

        <p></p>

        <p>4. Mobile Software</p>

        <p></p>

        <p>
          A. Mobile Software. We may make available software to access the
          Service via a mobile device (&quot;Mobile Software&quot;). To use the
          Mobile Software you must have a mobile device that is compatible with
          the Mobile Software. MW Studios does not warrant that the Mobile
          Software will be compatible with your mobile device. You may use
          mobile data in connection with the Mobile Software and may incur
          additional charges from your wireless provider for these services. You
          agree that you are solely responsible for any such charges. MW Studios
          hereby grants you a non-exclusive, non-transferable, revocable license
          to use a compiled code copy of the Mobile Software for one (1) account
          on one mobile device owned or leased solely by you, for your personal
          use. You may not: (i) modify, disassemble, decompile or reverse
          engineer the Mobile Software, except to the extent that such
          restriction is expressly prohibited by law; (ii) rent, lease, loan,
          resell, sublicense, distribute or otherwise transfer the Mobile
          Software to any third party or use the Mobile Software to provide time
          sharing or similar services for any third party; (iii) make any copies
          of the Mobile Software; (iv) remove, circumvent, disable, damage or
          otherwise interfere with security-related features of the Mobile
          Software, features that prevent or restrict use or copying of any
          content accessible through the Mobile Software, or features that
          enforce limitations on use of the Mobile Software; or (v) delete the
          copyright and other proprietary rights notices on the Mobile Software.
          You acknowledge that MW Studios may from time to time issue upgraded
          versions of the Mobile Software, and may automatically electronically
          upgrade the version of the Mobile Software that you are using on your
          mobile device. You consent to such automatic upgrading on your mobile
          device, and agree that the terms and conditions of this Agreement will
          apply to all such upgrades. Any third-party code that may be
          incorporated in the Mobile Software is covered by the applicable open
          source or third-party license EULA, if any, authorizing use of such
          code. The foregoing license grant is not a sale of the Mobile Software
          or any copy thereof, and MW Studios or its third-party partners or
          suppliers retain all right, title, and interest in the Mobile Software
          (and any copy thereof). Any attempt by you to transfer any of the
          rights, duties or obligations hereunder, except as expressly provided
          for in this Agreement, is void. MW Studios reserves all rights not
          expressly granted under this Agreement. If the Mobile Software is
          being acquired on behalf of the United States Government, then the
          following provision applies. The Mobile Software will be deemed to be
          &quot;commercial computer software&quot; and &quot;commercial computer
          software documentation&quot;, respectively, pursuant to DFAR Section
          227.7202 and FAR Section 12.212, as applicable. Any use, reproduction,
          release, performance, display or disclosure of the Service and any
          accompanying documentation by the U.S. Government will be governed
          solely by these Terms of Service and is prohibited except to the
          extent expressly permitted by these Terms of Service. The Mobile
          Software originates in the United States, and is subject to United
          States export laws and regulations. The Mobile Software may not be
          exported or re-exported to certain countries or those persons or
          entities prohibited from receiving exports from the United States. In
          addition, the Mobile Software may be subject to the import and export
          laws of other countries. You agree to comply with all United States
          and foreign laws related to use of the Mobile Software and the
          Service.
        </p>

        <p></p>

        <p>
          B. Mobile Software from Apple App Store. The following applies to any
          Mobile Software you acquire from the Apple App Store
          (&quot;Apple-Sourced Software&quot;): You acknowledge and agree that
          this Agreement is solely between you and MW Studios, not Apple, Inc.
          (&quot;Apple&quot;), and that Apple has no responsibility for the
          Apple-Sourced Software or content thereof. Your use of the
          Apple-Sourced Software must comply with the App Store Terms of
          Service. You acknowledge that Apple has no obligation whatsoever to
          furnish any maintenance and support services with respect to the
          Apple-Sourced Software. In the event of any failure of the
          Apple-Sourced Software to conform to any applicable warranty, you may
          notify Apple, and Apple will refund the purchase price for the
          Apple-Sourced Software to you; to the maximum extent permitted by
          applicable law, Apple will have no other warranty obligation
          whatsoever with respect to the Apple-Sourced Software, and any other
          claims, losses, liabilities, damages, costs or expenses attributable
          to any failure to conform to any warranty will be solely governed by
          this Agreement and any law applicable to MW Studios as provider of the
          software. You acknowledge that Apple is not responsible for addressing
          any claims of you or any third party relating to the Apple-Sourced
          Software or your possession and/or use of the Apple-Sourced Software,
          including, but not limited to: (i) product liability claims; (ii) any
          claim that the Apple-Sourced Software fails to conform to any
          applicable legal or regulatory requirement; and (iii) claims arising
          under consumer protection or similar legislation; and all such claims
          are governed solely by this Agreement and any law applicable to MW
          Studios as provider of the software. You acknowledge that, in the
          event of any third-party claim that the Apple-Sourced Software or your
          possession and use of that Apple-Sourced Software infringes that third
          party's intellectual property rights, MW Studios, not Apple, will be
          solely responsible for the investigation, defense, settlement and
          discharge of any such intellectual property infringement claim to the
          extent required by this Agreement. You and MW Studios acknowledge and
          agree that Apple, and Apple's subsidiaries, are third-party
          beneficiaries of this Agreement as relates to your license of the
          Apple-Sourced Software, and that, upon your acceptance of the terms
          and conditions of this Agreement, Apple will have the right (and will
          be deemed to have accepted the right) to enforce this Agreement as
          relates to your license of the Apple-Sourced Software against you as a
          third-party beneficiary thereof.
        </p>

        <p></p>

        <p>
          C.Mobile Software from Google Play Store. The following applies to any
          Mobile Software you acquire from the Google Play Store
          (&quot;Google-Sourced Software&quot;): (i) you acknowledge that the
          Agreement is between you and MW Studios only, and not with Google,
          Inc. (&quot;Google&quot;); (ii) your use of Google-Sourced Software
          must comply with Google's then-current Google Play Store Terms of
          Service; (iii) Google is only a provider of the Google Play Store
          where you obtained the Google-Sourced Software; (iv) MW Studios, and
          not Google, is solely responsible for its Google-Sourced Software; (v)
          Google has no obligation or liability to you with respect to
          Google-Sourced Software or the Agreement; and (vi) you acknowledge and
          agree that Google is a third-party beneficiary to the Agreement as it
          relates to MW Studios's Google-Sourced Software.
        </p>

        <p></p>

        <p>5. Our Proprietary Rights</p>

        <p></p>

        <p>
          Except for your User Content, the Service and all materials therein or
          transferred thereby, including, without limitation, software, game
          clients, images, text, graphics, illustrations, logos, patents,
          trademarks, service marks, copyrights, photographs, audio, videos,
          music, games, titles, computer code, themes, objects, characters,
          character names, stories, dialogue, phrases, concepts, artwork,
          animations, sounds, musical compositions, audiovisual effects, methods
          of operation, moral rights, documentation, in-game chat transcripts,
          character profile information, recordings of games played using a MW
          Studios game client, and User Content belonging to other Users (the
          &quot;MW Studios Content&quot;), and all Intellectual Property Rights
          related thereto, are the exclusive property of MW Studios and its
          licensors (including other Users who post User Content to the
          Service). Except as explicitly provided herein, nothing in this
          Agreement shall be deemed to create a license in or under any such
          Intellectual Property Rights, and you agree not to sell, license,
          rent, modify, distribute, copy, reproduce, transmit, publicly display,
          publicly perform, publish, adapt, edit or create derivative works from
          any MW Studios Content. Use of the MW Studios Content for any purpose
          not expressly permitted by this Agreement is strictly prohibited.
        </p>

        <p></p>

        <p>
          You may choose to or we may invite you to submit comments or ideas
          about the Service, including without limitation about how to improve
          the Service or our products (&quot;Ideas&quot;). By submitting any
          Idea, you agree that your disclosure is gratuitous, unsolicited and
          without restriction and will not place MW Studios under any fiduciary
          or other obligation, and that we are free to use the Idea without any
          additional compensation to you, and/or to disclose the Idea on a
          non-confidential basis or otherwise to anyone. You further acknowledge
          that, by acceptance of your submission, MW Studios does not waive any
          rights to use similar or related ideas previously known to MW Studios,
          or developed by its employees, or obtained from sources other than
          you.
        </p>

        <p></p>

        <p>6. MW Studios Property</p>

        <p></p>

        <p>
          The Service contains data, information, and other content not owned by
          you, such as reputational or status indicators in-world currency,
          and/or fictional property representing virtual achievements (for
          instance, trophies or powers) (&quot;MW Studios Property&quot;). You
          understand and agree that regardless of terminology used, MW Studios
          Property represents a limited license right governed solely by the
          terms of this Agreement and available for distribution at MW Studios's
          sole discretion. MW Studios Property is not redeemable for any sum of
          money or monetary value from MW Studios or any third party at any
          time. You further agree not to sell or redeem any MW Studios Property
          for any sum of money or monetary value from any third party. You
          acknowledge that you do not own the account you use to access the
          Service, nor do you possess any rights of access or rights to data
          stored by or on behalf of MW Studios on MW Studios servers, including
          without limitation any data representing or embodying any or all of
          your MW Studios Property. You agree that MW Studios has the absolute
          right to manage, regulate, control, modify and/or eliminate MW Studios
          Property as it sees fit in its sole discretion, in any general or
          specific case, and that MW Studios will have no liability to you based
          on its exercise of such right. All data on MW Studios's servers are
          subject to deletion, alteration or transfer. Notwithstanding any value
          attributed to such data by you or any third party, you understand and
          agree that any data, account history and account content residing on
          MW Studios's servers, may be deleted, altered, moved or transferred at
          any time for any reason in MW Studios's sole discretion, with or
          without notice and with no liability of any kind. MW Studios does not
          provide or guarantee, and expressly disclaims, any value, cash or
          otherwise, attributed to any data residing on MW Studios's servers.
        </p>

        <p></p>

        <p>7. Paid Services</p>

        <p></p>

        <p>
          A. In-Service Purchases. In certain areas of the Service, you may
          purchase with &quot;real world&quot; currency a limited, personal,
          non-transferable, non-sublicensable, revocable license to use: (a)
          &quot;virtual currency&quot; including, but not limited to virtual
          cash or gems; (b) certain types of MW Studios Property; and (c)
          certain other goods or services (&quot;Merchandise&quot;). MW Studios
          may manage, regulate, control, modify, or eliminate MW Studios
          Property and Merchandise at any time, with or without notice, and
          shall have no liability to you or any third party if MW Studios
          exercises any such rights. The transfer of MW Studios Property and
          Merchandise is prohibited except where expressly authorized in the
          Service. Other than as expressly authorized in the Service, you shall
          not sell, redeem, or otherwise transfer MW Studios Property or
          Merchandise to any person or entity, including but not limited to
          other Users or third parties. The provision of certain MW Studios
          Property for use on the Service is a service provided by MW Studios
          that commences promptly upon acceptance by MW Studios of your
          purchase.
        </p>

        <p></p>

        <p>
          B. No Refunds. Pleased be noted that while using your account,
          completed payments cannot be canceled and there are no refunds for
          completed payments. You may cancel your MW Studios account at any
          time; however, there are no refunds for cancellation. In the event
          that MW Studios suspends or terminates your account or this Agreement,
          you understand and agree that you shall receive no refund or exchange
          for any MW Studios Property, any unused time on a subscription, any
          license or subscription fees for any portion of the Service, any
          content or data associated with your account, or for anything else.
          Further, all purchases of MW Studios Property and Merchandise are
          non-refundable, and MW Studios shall not be required to provide a
          refund for any reason.
        </p>

        <p></p>

        <p>
          C. Payment Information; Taxes. All information that you provide in
          connection with a purchase or transaction or other monetary
          transaction interaction with the Service must be accurate, complete,
          and current. You agree to pay all charges incurred by users of your
          credit card, debit card, or other payment method used in connection
          with a purchase or transaction or other monetary transaction
          interaction with the Service at the prices in effect when such charges
          are incurred. You will pay any applicable taxes, if any, relating to
          any such purchases, transactions or other monetary transaction
          interactions.
        </p>

        <p></p>

        <p>8. Privacy</p>

        <p></p>

        <p>
          We care about the privacy of our Users. You understand that by using
          the Services you consent to the collection, use and disclosure of your
          personally identifiable information and aggregate data as set forth in
          our Privacy Policy https://www.arknights.global/privacy_policy, and to
          have your personally identifiable information collected, used,
          transferred to and processed in the Singapore.
        </p>

        <p></p>

        <p>9. Security</p>

        <p></p>

        <p>
          MW Studios cares about the integrity and security of your personal
          information. However, we cannot guarantee that unauthorized third
          parties will never be able to defeat our security measures or use your
          personal information for improper purposes. You acknowledge that you
          provide your personal information at your own risk.
        </p>

        <p></p>

        <p>10. DMCA Notice</p>

        <p></p>

        <p>
          Since we respect artist and content owner rights, it is MW Studios's
          policy to respond to alleged infringement notices that comply with the
          Digital Millennium Copyright Act of 1998 (&quot;DMCA&quot;).
        </p>

        <p></p>

        <p>
          If you believe that your copyrighted work has been copied in a way
          that constitutes copyright infringement and is accessible via the
          Service, please notify MW Studios's copyright agent as set forth in
          the DMCA. For your complaint to be valid under the DMCA, you must
          provide the following information in writing:
        </p>

        <p></p>

        <p>
          1. An electronic or physical signature of a person authorized to act
          on behalf of the copyright owner;
        </p>

        <p></p>

        <p>
          2. Identification of the copyrighted work that you claim has been
          infringed;
        </p>

        <p></p>

        <p>
          3. Identification of the material that is claimed to be infringing and
          where it is located on the Service;
        </p>

        <p></p>

        <p>
          4.Information reasonably sufficient to permit MW Studios to contact
          you, such as your address, telephone number, and, e-mail address;
        </p>

        <p></p>

        <p>
          5.A statement that you have a good faith belief that use of the
          material in themanner complained of is not authorized by the copyright
          owner, its agent, or law; and
        </p>

        <p></p>

        <p>
          6. A statement, made under penalty of perjury, that the above
          information is accurate, and that you are the copyright owner or are
          authorized to act on behalf of the owner.
        </p>

        <p></p>

        <p>
          The above information must be submitted to the following DMCA Agent:
        </p>

        <p></p>

        <p>
          Attn: DMCA Notice MW Studios (SINGAPORE) PRVATE LIMITED. Address: 12
          Woodlands Sq. #14 - 76 Woodsquare, Tower 1, Singapore 737715
          Telephone: 8126 9437 Email: play@mwstudios.io
        </p>

        <p></p>

        <p>
          UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL
          IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY
          AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND
          ATTORNEYS' FEES.
        </p>

        <p></p>

        <p>
          Please note that this procedure is exclusively for notifying MW
          Studios and its affiliates that your copyrighted material has been
          infringed. The preceding requirements are intended to comply with MW
          Studios's rights and obligations under the DMCA, including 17 U.S.C.
          &sect;512(c), but do not constitute legal advice. It may be advisable
          to contact an attorney regarding your rights and obligations under the
          DMCA and other applicable laws.
        </p>

        <p></p>

        <p>
          In accordance with the DMCA and other applicable law, MW Studios has
          adopted a policy of terminating, in appropriate circumstances, Users
          who are deemed to be repeat infringers. MW Studios may also at its
          sole discretion limit access to the Service and/or terminate the
          accounts of any Users who infringe any intellectual property rights of
          others, whether or not there is any repeat infringement.
        </p>

        <p></p>

        <p>11. Third-Party Links and Information</p>

        <p></p>

        <p>
          The Service may contain links to third-party materials that are not
          owned or controlled by MW Studios. MW Studios does not endorse or
          assume any responsibility for any such third-party sites, information,
          materials, products, or services. If you access a third-party website
          or service from the Service or share your User Content on or through
          any third-party website or service, you do so at your own risk, and
          you understand that this Agreement and MW Studios's Privacy Policy do
          not apply to your use of such sites. You expressly relieve MW Studios
          from any and all liability arising from your use of any third-party
          website, service, or content, including without limitation User
          Content submitted by other Users. Additionally, your dealings with or
          participation in promotions of advertisers found on the Service,
          including payment and delivery of goods, and any other terms (such as
          warranties) are solely between you and such advertisers. You agree
          that MW Studios shall not be responsible for any loss or damage of any
          sort relating to your dealings with such advertisers.
        </p>

        <p></p>

        <p>12. Indemnity</p>

        <p></p>

        <p>
          You agree to defend, indemnify and hold harmless MW Studios and its
          subsidiaries, agents, licensors, managers, and other affiliated
          companies, and their employees, contractors, agents, officers and
          directors, from and against any and all claims, damages, obligations,
          losses, liabilities, costs or debt, and expenses (including but not
          limited to attorney's fees) arising from: (i) your use of and access
          to the Service, including any data or content transmitted or received
          by you; (ii) your violation of any term of this Agreement, including
          without limitation your breach of any of the representations and
          warranties above; (iii) your violation of any third-party right,
          including without limitation any right of privacy or Intellectual
          Property Rights; (iv) your violation of any applicable law, rule or
          regulation; (v) User Content or any content that is submitted via your
          account including without limitation misleading, false, or inaccurate
          information; (vi) your willful misconduct; or (vii) any other party's
          access and use of the Service with your unique username, password or
          other appropriate security code.
        </p>

        <p></p>

        <p>13. No Warranty</p>

        <p></p>

        <p>
          The Service is provided on an &quot;as is&quot; and &quot;as
          available&quot; basis. Use of the Service is at your own risk. To the
          maximum extent permitted by applicable law, the Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, or non-infringement. No advice or information,
          whether oral or written, obtained by you from MW Studios or through
          the Service will create any warranty not expressly stated herein.
          Without limiting the foregoing, MW Studios, its subsidiaries, its
          affiliates, and its licensors do not warrant that the content is
          accurate, reliable or correct; that the Service will meet your
          requirements; that the Service will be available at any particular
          time or location, uninterrupted or secure; that any defects or errors
          will be corrected; or that the Service is free of viruses or other
          harmful components. Any content downloaded or otherwise obtained
          through the use of the Service is downloaded at your own risk and you
          will be solely responsible for any damage to your computer system or
          mobile device or loss of data that results from such download or your
          use of the Service.
        </p>

        <p></p>

        <p>
          MW Studios does not warrant, endorse, guarantee, or assume
          responsibility for any product or service advertised or offered by a
          third party through the Service or any hyperlinked website or service,
          and MW Studios will not be a party to or in any way monitor any
          transaction between you and third-party providers of products or
          services.
        </p>

        <p></p>

        <p>
          Some jurisdictions do not allow the exclusion and limitations of
          certain implied warranties, so the above exclusions may not apply to
          you. This agreement gives you specific legal rights, and you may also
          have other rights which vary from different jurisdictions. The
          disclaimers and exclusions under this Agreement will not apply to the
          extent prohibited by applicable law.
        </p>

        <p></p>

        <p>14. Limitation of Liability</p>

        <p></p>

        <p>
          To the maximum extent permitted by applicable law, in no event shall
          MW Studios, its affiliates, agents, directors, employees, suppliers or
          licensors be liable for any indirect, punitive, incidental, special,
          consequential or exemplary damages, including without limitation
          damages for loss of profits, goodwill, use, data or other intangible
          losses, arising out of or relating to the use of, or inability to use,
          this service. Under no circumstances will MW Studios be responsible
          for any damage, loss or injury resulting from hacking, tampering or
          other unauthorized access or use of the Service or your account or the
          information contained therein.
        </p>

        <p></p>

        <p>
          To the maximum extent permitted by applicable law, MW Studios assumes
          no liability or responsibility for any (i) errors, mistakes, or
          inaccuracies of content; (ii) personal injury or property damage, of
          any nature whatsoever, resulting from your access to or use of our
          service; (iii) any unauthorized access to or use of our secure servers
          and/or any and all personal information stored therein; (iv) any
          interruption or cessation of transmission to or from the Service; (v)
          any bugs, viruses, trojan horses, or the like that may be transmitted
          to or through our service by any third party; (vi) any errors or
          omissions in any content or for any loss or damage incurred as a
          result of the use of any content posted, emailed, transmitted, or
          otherwise made available through the Service; and/or (vii) User
          Content or the defamatory, offensive, or illegal conduct of any third
          party. In no event shall MW Studios, its affiliates, agents,
          directors, employees, suppliers, or licensors be liable to you for any
          claims, proceedings, liabilities, obligations, damages, losses or
          costs in an amount exceeding the amount you paid to MW Studios
          hereunder or $100.00, whichever is greater.
        </p>

        <p></p>

        <p>
          This limitation of liability section applies whether the alleged
          liability is based on contract, tort, negligence, strict liability, or
          any other basis, even if MW Studios has been advised of the
          possibility of such damage. The foregoing limitation of liability
          shall apply to the fullest extent permitted by law in the applicable
          jurisdiction.
        </p>

        <p></p>

        <p>
          Some jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitations or
          exclusions may not apply to you. This agreement gives you specific
          legal rights, and you may also have other rights which vary from
          different jurisdictions. The disclaimers, exclusions, and limitations
          of liability under this Agreement will not apply to the extent
          prohibited by applicable law.
        </p>

        <p></p>

        <p>
          The Service is controlled and operated from facilities in Singapore.
          MW Studios makes no representations that the Service is appropriate or
          available for use in other locations. Those who access or use the
          Service from other jurisdictions do so at their own volition and are
          entirely responsible for compliance with all applicable Singapore and
          local laws and regulations, including but not limited to export and
          import regulations. You may not use the Service if you are a resident
          of a country embargoed by Singapore, or are a foreign person or entity
          blocked or denied by Singapore government. Unless otherwise explicitly
          stated, all materials found on the Service are solely directed to
          individuals, companies, or other entities located in Singapore.
        </p>

        <p></p>

        <p>
          15. Governing Law, Arbitration, and Class Action/Jury Trial Waiver
        </p>

        <p></p>

        <p>
          A. Governing Law. You agree that: (i) the Service shall be deemed
          solely based in Singapore; and (ii) This Agreement shall be governed
          by the substantive laws of Singapore, without respect to its conflict
          of laws principles. The parties acknowledge that this Agreement
          evidences a transaction involving transnational commerce.
          Notwithstanding the preceding sentences with respect to the
          substantive law, any arbitration conducted pursuant to the terms of
          this Agreement shall be governed by applicable Singapore laws. The
          application of the United Nations Convention on Contracts for the
          International Sale of Goods is expressly excluded. You agree to submit
          to competent Singapore courts for any actions for which we retain the
          right to seek injunctive or other equitable relief in a court of
          competent jurisdiction to prevent the actual or threatened
          infringement, misappropriation or violation of a our copyrights,
          trademarks, trade secrets, patents, or other intellectual property or
          proprietary rights, as set forth in the Arbitration provision below,
          including any provisional relief required to prevent irreparable harm.
          You agree that Singapore is the proper forum for any appeals of an
          arbitration award or for trial court proceedings in the event that the
          arbitration provision below is found to be unenforceable.
        </p>

        <p></p>

        <p>
          B. Arbitration. Read this section carefully because it requires the
          parties to arbitrate their disputes and limits the manner in which you
          can seek relief from MW Studios. For any dispute with MW Studios, you
          agree to first contact us at play@mwstudios.io and attempt to resolve
          the dispute with us informally. In the unlikely event that MW Studios
          has not been able to resolve a dispute it has with you after sixty
          (60) days, we each agree to resolve any claim, dispute, or controversy
          (excluding any claims for injunctive or other equitable relief as
          provided below) arising out of or in connection with or relating to
          this Agreement, or the breach or alleged breach thereof (collectively,
          &quot;Claims&quot;), by binding arbitration by HKIAC, under
          thearbitration rules then in effect for HKIAC, except as provided
          herein. HKIAC may be contacted at http://www.hkiac.org. The
          arbitration will be conducted in Singapore, unless you and MW Studios
          agree otherwise. If you are using the Service for commercial purposes,
          each party will be responsible for paying any HKIAC filing,
          administrative and arbitrator fees in accordance with HKIAC rules, and
          the award rendered by the arbitrator shall include costs of
          arbitration, reasonable attorneys' fees and reasonable costs for
          expert and other witnesses. Any judgment on the award rendered by the
          arbitrator may be entered in any court of competent jurisdiction.
          Nothing in this Section shall be deemed as preventing MW Studios from
          seeking injunctive or other equitable relief from the courts as
          necessary to prevent the actual or threatened infringement,
          misappropriation, or violation of our data security, Intellectual
          Property Rights or other proprietary rights.
        </p>

        <p></p>

        <p>
          C. Class Action/Jury Trial Waiver. With respect to all persons and
          entities, regardless of whether they have obtained or used the Service
          for personal, commercial or other purposes, all claims must be brought
          in the parties' individual capacity, and not as a plaintiff or class
          member in any purported class action, collective action, private
          attorney general action or other representative proceeding. This
          waiver applies to class arbitration, and, unless we agree otherwise,
          the arbitrator may not consolidate more than one person's claims. You
          agree that, by entering into this Agreement, you and MW Studios are
          each waiving the right to a trial by jury or to participate in a class
          action, collective action, private attorney general action, or other
          representative proceeding of any kind.
        </p>

        <p></p>

        <p>16. General</p>

        <p></p>

        <p>
          A. Assignment. This Agreement, and any rights and licenses granted
          hereunder, may not be transferred or assigned by you, but may be
          assigned by MW Studios without restriction. Any attempted transfer or
          assignment in violation hereof shall be null and void.
        </p>

        <p></p>

        <p>
          B. Notification Procedures and Changes to the Agreement. MW Studios
          may provide notifications, whether such notifications are required by
          law or are for marketing or other business related purposes, to you
          via email notice, written or hard copy notice, or through posting of
          such notice on our website, as determined by MW Studios in our sole
          discretion. MW Studios reserves the right to determine the form and
          means of providing notifications to our Users, provided that you may
          opt out of certain means of notification as described in this
          Agreement. MW Studios is not responsible for any automatic filtering
          you or your network provider may apply to email notifications we send
          to the email address you provide us. MW Studios may, in its sole
          discretion, modify or update this Agreement from time to time, and so
          you should review this page periodically. When we change the Agreement
          in a material manner, we will update the &quot;last modified&quot;
          date at the bottom of this page and notify you that material changes
          have been made to the Agreement. Your continued use of the Service
          after any such change constitutes your acceptance of the new Terms of
          Use. If you do not agree to any of these terms or any future Terms of
          Use, do not use or access (or continue to access) the Service.
        </p>

        <p></p>

        <p>
          C. Entire Agreement/Severability. This Agreement, together with any
          amendments and any additional agreements you may enter into with MW
          Studios in connection with the Service, shall constitute the entire
          agreement between you and MW Studios concerning the Service. If any
          provision of this Agreement is deemed invalid by a court of competent
          jurisdiction, the invalidity of such provision shall not affect the
          validity of the remaining provisions of this Agreement, which shall
          remain in full force and effect, except that in the event of
          unenforceability of the universal Class Action/Jury Trial Waiver, the
          entire arbitration agreement shall be unenforceable.
        </p>

        <p></p>

        <p>
          D. No Waiver. No waiver of any term of this Agreement shall be deemed
          a further or continuing waiver of such term or any other term, and MW
          Studios's failure to assert any right or provision under this
          Agreement shall not constitute a waiver of such right or provision.
        </p>

        <p></p>

        <p>
          E. Contact. Please contact us at play@mwstudios.io with any questions
          regarding this Agreement.
        </p>

        <p></p>
      </Col>
    </>
  );
};

export default Terms;
