import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import { Col, Row, Image } from "react-bootstrap";
import "./FeaturesCarousel.css";

const featImages = require.context("../assets/features", true);
const featImageList = featImages.keys().map((image) => featImages(image));

const FeaturesCarousel = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: "60px",
    adaptiveHeight: true,
    beforeChange: (current, next) => setImgIndex(next),
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          speed: 200,
          centerMode: true,
          className: "center",
        },
      },
    ],
  };

  const [imgIndex, setImgIndex] = useState(0);
  let width = window.innerWidth;

  if (width > 768) {
    return (
      <>
        <Col
          id="features"
          className="text-center align-items-center features-image"
        >
          <Row className="">
            <h2 className="slider-gap mb-5">FEATURES</h2>
          </Row>
          <Row className="slideshow-row">
            <Slider className="" {...settings}>
              {featImageList.map((image, index) => (
                <Row
                  className={
                    index === imgIndex ? "slider activeSlider" : "slider"
                  }
                >
                  <Image key={index} src={image} alt={`image-${index}`} />
                </Row>
              ))}
            </Slider>
          </Row>
        </Col>
      </>
    );
  } else {
    return (
      <>
        <Col
          id="features"
          className="text-center align-items-center features-image-small"
        >
          <Row className="">
            <h2 className="slider-gap-small mb-3">FEATURES</h2>
          </Row>
          <Row className="slideshow-row">
            <Slider className="" {...settings}>
              {featImageList.map((image, index) => (
                <Row
                  className={
                    index === imgIndex ? "slider activeSlider" : "slider"
                  }
                >
                  <Image key={index} src={image} alt={`image-${index}`} />
                </Row>
              ))}
            </Slider>
          </Row>
        </Col>
      </>
    );
  }
};

export default FeaturesCarousel;
