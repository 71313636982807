import React, { useState } from "react";
import {
  ChakraProvider,
  theme,
  Flex,
  Input,
  Button,
  Box,
  Stack,
  Container,
} from "@chakra-ui/react";
import "./MailingBlock.css";
import jsonp from "jsonp";

const MailingBlock = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const url =
      "https://mwstudios.us21.list-manage.com/subscribe/post-json?u=0671a28083a10e0b4ddeba5de&amp;id=dedb013f70&amp;f_id=00b8e4e6f0";
    jsonp(
      `${url}&b_0671a28083a10e0b4ddeba5de_dedb013f70=${email}`,
      { param: "c" },
      (_, data) => {
        const { msg, result } = data;
        // do something with response
        alert(msg);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <ChakraProvider theme={theme}>
        <Box id="contact">
          <Flex
            className="p-4"
            as="form"
            mx="auto"
            maxWidth="500px"
            my="50px"
            onSubmit={onSubmit}
            direction="column"
            justifyContent="center"
          >
            <h2 className="mt-3" align="center">
              JOIN OUR MAILING LIST
            </h2>
            <p className=" mb-4" align="center">
              Sign up to receive the latest Chibi Rumble news and update
            </p>
            <Stack spacing={4} direction="row" align="center">
              <Input
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                bg="#DED6FF"
                _hover={{ bg: "gray.200" }}
                borderRadius="112px"
              />
              <Button
                bg="#7E4EDA"
                borderRadius="112px"
                type="submit"
                color="white"
                padding="5"
                isLoading={loading}
                _hover={{ bg: "gray.800" }}
                _active={{ bg: "gray.600" }}
              >
                Subscribe
              </Button>
            </Stack>
          </Flex>
        </Box>
      </ChakraProvider>
    </>
  );
};

export default MailingBlock;

//https://mwstudios.us21.list-manage.com/subscribe/post-json?u=0671a28083a10e0b4ddeba5de&amp;id=dedb013f70&amp;f_id=00b8e4e6f0
//<input type="email" name="b_0671a28083a10e0b4ddeba5de_dedb013f70" tabindex="-1" value="">
