import React from "react";
import Marquee from "react-fast-marquee";
import "./CarCarousel.css";
import { Container, Image } from "@chakra-ui/react";

const kartImages = require.context("../assets/karts", true);
const kartImageList = kartImages.keys().map((image) => kartImages(image));

const CarCarousel = () => {
  return (
    <>
      <div>
        <Marquee speed="150" width="20%" className="mt-4">
          {kartImageList.map((image, index) => (
            <Container className="marq-container">
              <Image
                className="marq-image"
                key={index}
                src={image}
                alt={`image-${index}`}
              />
            </Container>
          ))}
        </Marquee>
      </div>
    </>
  );
};

export default CarCarousel;
