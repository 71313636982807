import React from "react";
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";
import { Container } from "react-bootstrap";
import "./AppDownloadBar.css";

const AppDownloadBar = () => {
  const APKUrl =
    "https://play.google.com/store/apps/details?id=host.exp.exponent";
  const iOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";
  return (
    <>
      <Container className="w-100 d-flex justify-content-center mb-4">
        <ButtonsContainer className="">
          <GooglePlayButton
            url={APKUrl}
            theme={"dark"}
            className="custom-style p-2 justify-content-center align-items-center"
          />
          <AppStoreButton
            url={iOSUrl}
            theme={"dark"}
            className="custom-style p-2 justify-content-center align-items-center"
          />
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default AppDownloadBar;
