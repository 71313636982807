import React from "react";
import { Row, Nav, Navbar, Col, Image, Container } from "react-bootstrap";
import "./HeaderBar.css";
import facebook from "../assets/icon_facebook.png";
import tiktok from "../assets/icon_tiktok.png";
import discord from "../assets/icon_discord.png";
import instagram from "../assets/icon_instagram.png";
import $ from "jquery";

const HeaderBar = () => {
  let width = window.innerWidth;
  const mainContentShow = () => {
    $("#terms").addClass("d-none");
    $("#privacy").addClass("d-none");
    $("#main-content").removeClass("d-none");
    window.scrollTo(-10, -10);
  };

  if (width > 768) {
    return (
      <>
        <Navbar sticky="top" className="color-nav" data-bs-theme="dark">
          <Row className="m-2 w-100 nav-row">
            <Col>
              <Nav className="m-auto">
                <Nav.Link href="/#introduction" onClick={mainContentShow}>
                  Introduction
                </Nav.Link>
                <Nav.Link href="/#features">Features</Nav.Link>
                <Nav.Link href="/#gallery">Gallery</Nav.Link>
                {/* <Nav.Link href="/news">News</Nav.Link> */}
                <Nav.Link href="/#contact">Contact</Nav.Link>
              </Nav>
            </Col>
            <Col className="ml-4">
              <Row className="socialrow align-items-center">
                <Col className="">
                  <Nav.Link className="mt-1" href="https://mwstudios.io/">
                    <Image src={discord} fluid />
                  </Nav.Link>
                </Col>
                <Col>
                  <Nav.Link href="https://mwstudios.io/">
                    <Image src={facebook} fluid />
                  </Nav.Link>
                </Col>
                <Col>
                  <Nav.Link href="https://mwstudios.io/">
                    <Image src={instagram} fluid />
                  </Nav.Link>
                </Col>

                <Col>
                  <Nav.Link href="https://mwstudios.io/">
                    <Image src={tiktok} fluid />
                  </Nav.Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Navbar>
      </>
    );
  } else {
    return (
      <>
        <Navbar
          sticky="top"
          className="color-nav text-center justify-content-center"
          data-bs-theme="dark"
        >
          <Row className="mini-nav-row">
            <Nav className="">
              <Nav.Link href="/#introduction" onClick={mainContentShow}>
                Introduction
              </Nav.Link>
              <Nav.Link href="/#features">Features</Nav.Link>
              <Nav.Link href="/#gallery">Gallery</Nav.Link>
              <Nav.Link href="/#contact">Contact</Nav.Link>
            </Nav>
          </Row>
        </Navbar>
      </>
    );
  }
};

export default HeaderBar;
