import React, { useState } from "react";

import MailingBlock from "../components/MailingBlock";
import CarCarousel from "../components/CarCarousel";
import ResidentsCarousel from "../components/ResidentsCarousel";
import MainBanner from "../components/MainBanner";
import Introduction from "../components/Introduction";
import FeaturesCarousel from "../components/FeaturesCarousel";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";
import $ from "jquery";

export default function HomePage() {
  return (
    <>
      <div id="main-content">
        <MainBanner />
        <Introduction />
        <FeaturesCarousel />
        <ResidentsCarousel />
        <CarCarousel />
        <MailingBlock id="contact" />
      </div>
      <div id="privacy" className="d-none">
        <Privacy />
      </div>
      <div id="terms" className="d-none">
        <Terms />
      </div>
    </>
  );
}
