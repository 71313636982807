import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import HeaderBar from "./components/HeaderBar";
import FooterBar from "./components/FooterBar";

function App() {
  return (
    <>
      <div className="App">
        <HeaderBar />
        <Routes>
          <Route exact path="/" Component={HomePage} />
        </Routes>
        <FooterBar />
      </div>
    </>
  );
}

export default App;
