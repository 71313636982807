import React from "react";
import { Container, Carousel, Card, Row, Col } from "react-bootstrap";
import "./ResidentsCarousel.css";
import nextIcon from "../assets/carousel/Right.png";
import prevIcon from "../assets/carousel/Left.png";

const residents = require.context("../assets/residents", true);
const residentsList = residents.keys().map((image) => residents(image));

//The index follows image placement in the folder
const residents_text = [
  "Roger the Rabbit is a hardworking and earnest young bunny. He is always ready to lend out a helping paw and the townsfolks love their friendly furry pal. His favourite catchphrase is: “Roger that!”  ",
  "Charlie the Chick is an innocent little bird. Or…is he? Rumour has it that he was often sighted near blown up buildings. Coincidence or correlation? ",
  "Gerald the Gecko is a nerdy smart fella who enjoys reading and bug-catching. He will supply you with a 2-hour lecture on insects’ trivia any time of the day if you ask! ",
  "Shelley is a friendly girl who puts everyone’s heart at ease. The villagers love to be around amiable Shelley and she always keep the atmosphere cheerful and warm.",
  "Melvin is Shelley’s cool older brother. He doesn’t talk much, but his skills with karts is top notch and no one in town can drive as well as him.", 
  "Satoshi the Ninja hail from a mysterious land in the East. No one knows what he came to town for, but he puts up a good show when riding the karts, so everyone happily accepted him. ",
  "Richie the Rich man is the richest man in town. He has so many riches that he does not know what to do with them. ",
  "Steve the Petrolman helps to keep the town’s karts running. He always get the best and most affordable petrol for the townsfolk. Sometimes, he personally tests the karts after refilling the tanks. ",
  "Tyler the Tiger is a shy but friendly kid who will not hesitate to help an old lady cross the road! He was nominated Best Townsfolk three years in a row. "
];

const ResidentsCarousel = () => {
  let width = window.innerWidth;
  if (width > 768) {
    return (
      <>
        <Container id="gallery" className="pt-5">
          <h2 className="text-center">MEET THE RESIDENTS</h2>
          <Container className="">
            <Carousel
              className=""
              nextIcon={<img className="button-size" src={nextIcon} />}
              prevIcon={<img className="button-size" src={prevIcon} />}
              indicators={false}
            >
              {residentsList.map((image, index) => (
                <Carousel.Item className="">
                  <Card className="border-0">
                    <Row className="resident-carousel-row">
                      <Col className="p-4 d-flex justify-content-end">
                        <Card.Img
                          className="resident-image"
                          variant="left"
                          key={index}
                          src={image}
                          alt={`image-${index}`}
                        />
                      </Col>
                      <Col className="d-flex justify-content-start align-self-center w-25">
                        <Card.Body className="">
                          <Card.Text className="card-text text-center p-4 w-50">
                            <p>{residents_text[index]}</p>
                          </Card.Text>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Carousel.Item>
              ))}
            </Carousel>
          </Container>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <h2 className="text-center my-5">MEET THE RESIDENTS</h2>
        <Carousel
          id="gallery"
          className=""
          nextIcon={<img className="button-size" src={nextIcon} />}
          prevIcon={<img className="button-size" src={prevIcon} />}
          indicators={false}
        >
          {residentsList.map((image, index) => (
            <Carousel.Item className="">
              <Card className="border-0">
                <Col className="resident-carousel">
                  <Row>
                    <Card.Img
                      className="resident-image"
                      variant="top"
                      key={index}
                      src={image}
                      alt={`image-${index}`}
                    />
                  </Row>
                  <Row>
                    <Card.Body className="m-2">
                      <Card.Text className="card-text text-center p-4">
                        <p>{residents_text[index]}</p>
                      </Card.Text>
                    </Card.Body>
                  </Row>
                </Col>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </>
    );
  }
};

export default ResidentsCarousel;
